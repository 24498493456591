import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Heading,
  VStack,
  Text,
  Container,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  HStack,
  Tr,
  Select,
  Checkbox,
  Flex,
  Input,
} from '@chakra-ui/react';
import ContentLayout from '../../layouts/ContentLayout';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBatchSheets, createBatch, downloadBatchSheet, uploadBatchSheet, createPending } from '../../store/actions/manualPayoutBatchActions';
import dayjs from 'dayjs';
import { setAlert } from '../../store/actions/alertActions';
import { Link } from 'react-router-dom';

const ManualPayoutBatch = () => {
  const dispatch = useDispatch();

  const { sheets, pendingSheets, loading, creatingBatch, uploadingSheet } = useSelector((state) => state.manualPayoutBatch);
  const [payoutPartner, setPayoutPartner] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [selectedPayoutIds, setSelectedPayoutIds] = useState([]);
  const payoutPartners = ['ARMSTRONG'];
  const [selectAll, setSelectAll] = useState(false);

  const [fromTime, setFromTime] = useState('');
  const [toTime, setToTime] = useState('');
  const [sortOrder, setSortOrder] = useState('recent');

  useEffect(() => {
    if (payoutPartner && orderStatus === 'CREATED') {
      dispatch(fetchBatchSheets(payoutPartner));
    } else if (payoutPartner && orderStatus === 'PENDING') {
      dispatch(createPending(payoutPartner));
    }
  }, [payoutPartner, orderStatus, dispatch]);

  const handleCreateBatch = () => {
    if (!payoutPartner) {
      dispatch(setAlert({ title: 'Please select a payout partner first', description: 'Please select a payout partner first', status: 'error' }));
      return;
    }

    if (orderStatus === 'PENDING' && selectedPayoutIds.length === 0) {
      dispatch(setAlert({ title: 'Please select at least one pending order', description: 'Please select at least one pending order to create a batch', status: 'error' }));
      return;
    }

    const payload = {
      payout_partner: payoutPartner,
      payout_ids: orderStatus === 'PENDING' ? selectedPayoutIds : []
    };

    dispatch(createBatch(payload));
  };

  const handleDownloadClick = (batchId) => {
    dispatch(downloadBatchSheet(batchId));
  };

  const handleUploadClick = (batchId, event) => {
    const file = event.target.files[0];
    if (!file) return;

    dispatch(uploadBatchSheet(batchId, file));
  };

  const formatDate = (dateString) => {
    return dayjs(dateString).format('MMM D, YYYY h:mm A');
  };

  const handlePayoutPartnerChange = (e) => {
    setPayoutPartner(e.target.value);
    setOrderStatus('');
    setSelectedPayoutIds([]);
    setSelectAll(false);
  };

  const handleOrderStatusChange = (e) => {
    setOrderStatus(e.target.value);
    setSelectedPayoutIds([]);
    setSelectAll(false);
  };

  const handlePendingOrderSelect = (payoutId) => {
    setSelectedPayoutIds(prev => {
      const updatedSelection = prev.includes(payoutId)
        ? prev.filter(id => id !== payoutId)
        : [...prev, payoutId];
      
      setSelectAll(updatedSelection.length === filteredAndSortedOrders.length);
      return updatedSelection;
    });
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedPayoutIds(filteredAndSortedOrders.map(sheet => sheet.payoutId));
    } else {
      setSelectedPayoutIds([]);
    }
  };

  const filterAndSortOrders = (orders) => {
    if (!Array.isArray(orders)) return [];

    let filteredOrders = [...orders];

    if (fromTime) {
      filteredOrders = filteredOrders.filter(order => dayjs(order.createdAt).isAfter(dayjs(fromTime)));
    }
    if (toTime) {
      filteredOrders = filteredOrders.filter(order => dayjs(order.createdAt).isBefore(dayjs(toTime)));
    }

    filteredOrders.sort((a, b) => {
      const dateA = dayjs(a.createdAt);
      const dateB = dayjs(b.createdAt);
      return sortOrder === 'recent' ? dateB.diff(dateA) : dateA.diff(dateB);
    });

    return filteredOrders;
  };

  const filteredAndSortedOrders = filterAndSortOrders(orderStatus === 'PENDING' ? pendingSheets : sheets);

  return (
    <ContentLayout>
      <VStack spacing={4} align="start" width="full">
        <Container maxW="container.xl" py={10} bg="black" color="white">
          <Box mb={6}>
            <Heading size="lg" color="green.400">
              Manual Payout Batch Process
            </Heading>
            <Text fontSize="md" color="gray.400">
              Manage and download your payout batches efficiently.
            </Text>
          </Box>
          <VStack spacing={4} align="stretch" mb={6}>
            <HStack spacing={4}>
              <Select
                placeholder="Select Payout Partner"
                bg="gray.800"
                borderColor="green.400"
                color="white"
                size="lg"
                _hover={{ bg: "gray.700" }}
                _focus={{ borderColor: "green.400", boxShadow: "0 0 0 1px green.400" }}
                value={payoutPartner}
                onChange={handlePayoutPartnerChange}
                flex={1}
              >
                {payoutPartners.map((partner) => (
                  <option key={partner} value={partner}>
                    {partner}
                  </option>
                ))}
              </Select>
              <Select
                placeholder="Filter orders"
                bg="gray.800"
                color="white"
                size="lg"
                _focus={{
                  border: '1px solid #81EBAB',
                  boxShadow: 'none'
                }}
                value={orderStatus}
                onChange={handleOrderStatusChange}
                flex={1}
              >
                <option value="CREATED">Created</option>
                <option value="PENDING">Pending</option>
              </Select>
            </HStack>
            <HStack spacing={4}>
              <Input
                type="datetime-local"
                bg="gray.800"
                color="white"
                value={fromTime}
                onChange={(e) => setFromTime(e.target.value)}
                placeholder="From time"
                size="lg"
                flex={1}
              />
              <Input
                type="datetime-local"
                bg="gray.800"
                color="white"
                value={toTime}
                onChange={(e) => setToTime(e.target.value)}
                placeholder="To time"
                size="lg"
                flex={1}
              />
              <Select
                bg="gray.800"
                color="white"
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
                placeholder="Sort orders"
                size="lg"
                flex={1}
              >
                <option value="recent">Recent to Oldest</option>
                <option value="oldest">Oldest to Recent</option>
              </Select>
            </HStack>
          </VStack>
          
          {loading ? (
            <Text color="white">Loading...</Text>
          ) : orderStatus === 'PENDING' ? (
            <>
              <Flex justifyContent="space-between" alignItems="center" mb={4}>
                <Text color="white">
                  Selected Orders: {selectedPayoutIds.length} / {filteredAndSortedOrders.length}
                </Text>
                <Button
                  variant="outline"
                  colorScheme="brand"
                  size="md"
                  px="5"
                  onClick={handleCreateBatch}
                  isDisabled={selectedPayoutIds.length === 0}
                  isLoading={creatingBatch}
                  loadingText="Creating"
                >
                  CREATE BATCH
                </Button>
              </Flex>
              <Table variant="simple" colorScheme="whiteAlpha">
                <Thead>
                  <Tr>
                    <Th color="gray.400">
                      <HStack spacing="1rem">
                        <Checkbox
                          isChecked={selectAll}
                          onChange={handleSelectAll}
                          colorScheme="gray"
                        />
                        <Text>Select All</Text>
                      </HStack>
                    </Th>
                    <Th color="gray.400">Order ID</Th>
                    <Th color="gray.400">Payout ID</Th>
                    <Th color="gray.400">Amount (INR)</Th>
                    <Th color="gray.400">Created At</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredAndSortedOrders.length > 0 ? (
                    filteredAndSortedOrders.map((entry) => (
                      <Tr key={entry.payoutId}>
                        <Td>
                          <Checkbox
                            isChecked={selectedPayoutIds.includes(entry.payoutId)}
                            onChange={() => handlePendingOrderSelect(entry.payoutId)}
                          />
                        </Td>
                        <Td color="white">{entry.orderId}</Td>
                        <Td color="white">{entry.payoutId}</Td>
                        <Td color="white">{entry.inrAmt ? entry.inrAmt.toFixed(2) : 'N/A'}</Td>
                        <Td color="white">{entry.createdAt ? formatDate(entry.createdAt) : 'N/A'}</Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan={5} color="white">No pending sheets available</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </>
          ) : orderStatus === 'CREATED' ? (
            <Table variant="simple" colorScheme="whiteAlpha">
              <Thead>
                <Tr>
                  <Th color="gray.400">Batch ID</Th>
                  <Th color="gray.400">Status</Th>
                  <Th color="gray.400">Created At</Th>
                  <Th color="gray.400">Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredAndSortedOrders.length > 0 ? (
                  filteredAndSortedOrders.map((sheet) => (
                    <Tr key={sheet.batchId}>
                      <Td color="white">
                        <Link to={`/manual-payouts/batch/${sheet.batchId}`} style={{ color: 'lightblue', textDecoration: 'underline' }}>
                          {sheet.batchId || 'N/A'}
                        </Link>
                      </Td>
                      <Td color="white">{sheet.status || 'N/A'}</Td>
                      <Td color="white">{sheet.createdAt ? formatDate(sheet.createdAt) : 'N/A'}</Td>
                      <Td>
                        <HStack spacing={2}>
                          <Button
                            colorScheme="green"
                            onClick={() => handleDownloadClick(sheet.batchId)}
                          >
                            Download
                          </Button>
                          {(sheet.status === 'CREATED' || sheet.status === 'PROCESSING') && (
                            <Button
                              as="label"
                              htmlFor={`file-upload-${sheet.batchId}`}
                              colorScheme="whiteAlpha"
                              cursor="pointer"
                              isLoading={uploadingSheet === sheet.batchId}
                              loadingText="Uploading"
                            >
                              {uploadingSheet === sheet.batchId ? 'Uploading' : 'Upload'}
                              <input
                                id={`file-upload-${sheet.batchId}`}
                                type="file"
                                accept=".csv"
                                style={{ display: 'none' }}
                                onChange={(e) => handleUploadClick(sheet.batchId, e)}
                              />
                            </Button>
                          )}
                        </HStack>
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={4} color="white">No sheets available</Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          ) : (
            <Text color="white">Please select a payout partner and order status to view data.</Text>
          )}
        </Container>
      </VStack>
    </ContentLayout>
  );
};

export default ManualPayoutBatch;